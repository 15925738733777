// history模式
import {
    createRouter,
    createWebHashHistory,
} from 'vue-router'
const routes = [
    // 路由的默认路径
    {
        path: '/',
        name: '/',
        component: () => import('../views/homePage/homePage.vue'),
        exact: true // 精确匹配，只有完全匹配时才能访问

    }, {
        path: '/platform',
        name: "platform",
        component: () => import('../views/platformPage/platformPage.vue'),
        exact: true
    }, {
        path: '/technology',
        name: "technology",
        component: () => import('../views/technologyPage/technologyPage.vue'),
        exact: true
    }, {
        path: '/confirm',
        name: "confirm",
        component: () => import('../views/confirmPage/confirmPage.vue'),
        exact: true
    }, {
        path: '/serve',
        name: "serve",
        component: () => import('../views/servePage/servePage.vue'),
        exact: true
    }
    , {
        path: '/success',
        name: "success",
        component: () => import('../views/successPage/successPage.vue'),
        exact: true
    }
    , {
        path: '/about',
        name: "about",
        component: () => import('../views/aboutPage/aboutPage.vue'),
        exact: true
    },
    {
        path: "/confirmDetails",
        name: "confirmDetails",
        component: () => import('../views/confirmPage/confirmDetails.vue'),
        exact: true
    },
    {
        path: "/successDetails",
        name: "successDetails",
        component: () => import('../views/successPage/successDetails.vue'),
        exact: true
    },
    {

        path: '/:catchAll(.*)',
        name: "404",
        // redirect: { name: 'NotFound', path: '/404' }, // 未匹配到路径时重定向到404页面  

        component: () => import('../components/notFound404.vue'),

    }

]



// 创建路由对象
const router = createRouter({
    history: createWebHashHistory(),
    routes,

})









export default router;
