import { createApp } from 'vue'
import './assets/css/global.css'
import './assets/css/responsive.scss'
import 'bootstrap/dist/css/bootstrap.css';

import router from './router/index.js'
import {createPinia} from 'pinia'

import App from './App.vue'

const Pinia = createPinia()
const app = createApp(App);





app.use(router).use(Pinia).mount('#app')
