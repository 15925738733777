<script setup>
import popBox from "./popBox.vue";
import { ref } from "vue";
const isConsult = ref(false);
const contactValue = ref(-1);
const contactData = ref([
  {
    img: require("../../src/assets/images/contactBox/tell.png"),
    img_active: require("../../src/assets/images/contactBox/tell_active.png"),
    text: "电话沟通",
  },
  {
    img: require("../../src/assets/images/contactBox/consult.png"),
    img_active: require("../../src/assets/images/contactBox/consult_active.png"),
    text: "在线咨询",
  },
  {
    img: require("../../src/assets/images/contactBox/code.png"),
    img_active: require("../../src/assets/images/contactBox/code_active.png"),
    text: "扫码咨询",
  },
  {
    img: require("../../src/assets/images/contactBox/backTop.png"),
    img_active: require("../../src/assets/images/contactBox/backTop_active.png"),
    text: "回到顶部",
  },
]);
const handleMove = (index) => {
  console.log("handleMove-----", index);
  contactValue.value = index;
};
const hadleClick = (index) => {
    console.log("hadleClick-----", index);
  if (index == 1) {
    isConsult.value = true;
  }
  if(index === 3){
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
};
const handleClosePop = (value) => {
  console.log("handleClosePop-----", value);
  isConsult.value = value; // 根据参数值来更新isClosePop的值
};
</script>
<template>
  <!-- 固定在右侧的联系box组件 -->
  <div class="contactBox_wrapper">
    <div class="contactBox_container">
      <div class="contact_box">
        <div
          class="contact_item"
          v-for="(item, index) in contactData"
          :key="index"
          @mousemove="handleMove(index)"
          @mouseleave="contactValue = -1"
          @click="hadleClick(index)"
        >
          <img
            v-if="contactValue == index"
            class="img"
            :src="item.img"
            alt=""
          />
          <img v-else class="img" :src="item.img_active" alt="" />
          <div class="text">{{ item.text }}</div>

          <div v-if="index == 0 && contactValue == 0" class="tell_hover">
            <span>电话沟通：13382079201</span>
            <div class="caret-right"></div>
          </div>

          <div v-if="index == 1 && contactValue == 1" class="consult_hover">
            <span>点击立即咨询</span>
            <div class="caret-right"></div>
          </div>

          <div v-if="index == 2 && contactValue == 2" class="code_hover">
            <div class="imgs">
              <img
                src="../../src//assets/images/contactBox/erweima.jpg"
                alt=""
              />
            </div>
            <div class="texts">微信扫码一对一沟通</div>
            <div class="texts">获取专业解决方案</div>
            <div class="caret-right"></div>
          </div>

          
        </div>
      </div>
    </div>
    <popBox :isClosePop="isConsult" @closePop="handleClosePop"></popBox>
  </div>
</template>



<style lang="scss">
.contactBox_container {
  position: fixed;
  width: 64px;

  height: auto;
  right: 0px;
  top: 33%;
  z-index: 9998;

  box-sizing: border-box;

  background: #005eff;
  border-radius: 6px;


  padding: 5px 8px;
  display: block;
  text-align: center;
  .contact_box {
    position: relative;
    .contact_item {
     
      padding: 8px 0px;
      box-sizing: border-box;
      border-bottom: 1px solid #fff;
      .img {
        width: 20px;
        height: 20px;
      }
      .text {
        font-size: 11px;
        color: #e6e6e6;
        margin-top: 2px;
      }

      .tell_hover,
      .consult_hover,
      .code_hover {
        position: absolute;
        width: 140px;
        background-color: #fefefe;
        border-radius: 4px;
        padding: 8px 12px;
        top: 10px;
        right: 75px;
        font-size: 13px;
        color: #818080;
        z-index: 9998;
        box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.12);
        .caret-right {
          width: 12px;
          height: 12px;
          background-color: #fefefe;

          box-shadow: 0px 0px 20px 0px rgba(117, 115, 115, 0.12);
          transform: rotate(45deg);
          position: absolute;
          right: -4px;
          top: 50%;
          z-index: 911;
          margin-top: -6px;
        }
      }
      .consult_hover {
        top: 70px;
      }
      .code_hover {
        top: 120px;
        // height: 180px;
        .caret-right {
          top: 20%;
        }
        .imgs {
          img {
            width: 110px;
          }
        }
        .texts {
          color: #0b0b0b;
          font-size: 10px;
        }
      }
    }
    .contact_item:hover {
      .text {
        color: #fff;
      }
    }
    .contact_item:nth-child(4){
        border: none;
        cursor: pointer;
    }
  }
}
</style>