
<script setup>
import NavBar from "./components/NavBar.vue";
// import FooterBar from "./components/FooterBar.vue";
import contactBox from "./components/contactBox.vue";
import { onMounted } from "vue";
onMounted(()=>{
  console.log("app-----------");
  
})
</script>
<template>
  <div class="app container-fluid">
    <NavBar />

    <router-view></router-view>

    <!-- <FooterBar /> -->
    <contactBox />
   
  </div>
</template>

<style lang="scss" scoped>
.container-fluid {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  margin: 0px !important;
  overflow-x: hidden;
}
</style>