<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

console.log(router);

const navBarIndex = ref(0);
const navBarData = [
  {
    name: "首页",
    path: "/",
  },
  {
    name: "蜜造平台",
    path: "/platform",
  },
  {
    name: "技术优势",
    path: "/technology",
  },
  {
    name: "解决方案",
    path: "/confirm",
  },
  {
    name: "服务项目",
    path: "/serve",
  },
  {
    name: "成功案例",
    path: "/success",
  },
  {
    name: "关于我们",
    path: "/about",
  },
];

const goToRouter = (index, path) => {
  console.log("goToRouter--index-----", index, path);

  // router.push(path);
  // http://localhost:8080/#/technology  本地地址
  // file:///E:/web/dist/index.html#/confirm 打包地址
  // http://203.195.64.24:9022/#/confirm  线上地址

  // window.open("./#" + path);

  window.open("./#" + path); //用于不同的主机名 以相对地址来改变
};

onMounted(() => {
  const href = window.location.href;
  console.log("href------", href);
  const pattern1 = /\/(\w+)/g; // 使用正则表达式匹配 "#/后接一个或多个字母数字字符，然后是?"

  const result1 = href.match(pattern1);
  console.log("result1-----", result1);
  console.log("navBarIndex.value-----", navBarIndex.value);
  if (result1) {

    navBarData.forEach((item, index) => {
      if (item.path == result1[1]) {
        navBarIndex.value = index;
      }
     
    });
  }

  const pattern2 = /\/(\w+)\?/g;
  const result2 = href.match(pattern2);
  console.log("result2-----", result2);
  if (result2) {
    if (result2[0] == "/confirmDetails?") {
      navBarIndex.value = 2;
    }
    if (result2[0] == "/successDetails?") {
      navBarIndex.value = 4;
    }
  }

  console.log("navBarIndex.value----", navBarIndex.value);
});
</script>

<template>
  <div class="navBar_wrapper">
    <div class="navBar_slot"></div>
    <div class="navBar_container">
      <header class="nav_box flex">
        <div class="nav_left flex justify-center align-center">
          <img src="../assets//images/home/logo.png" alt="" />
        </div>
        <div class="nav_center">
          <ul class="flex">
            <li
              v-for="(item, index) in navBarData"
              :key="item"
              :class="navBarIndex == index ? 'li_active' : ''"
              @click="goToRouter(index, item.path)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="nav_right flex justify-center">
          <div class="tell_box flex flex-column justify-center align-center">
            <div class="tell_top">
              <img src="../assets//images/home/tell_icon.png" alt="" />
              <span>服务热线</span>
            </div>
            <div class="tell_bottom">
              <span>13382079201</span>
            </div>
          </div>
          <div class="menu_icon flex align-center">
            <img class="" src="../assets//images/home/menu_icon.png" alt="" />
          </div>
        </div>
      </header>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.navBar_slot {
  height: 84px;
}
.navBar_container {
  width: 100%;
  height: 84px;
  position: fixed;
  z-index: 9998;
  top: 0px;
}
.nav_box {
  width: 100%;
  height: 84px;
  background-color: #313645;
  position: relative;

  .nav_left,
  .nav_right {
    width: 360px;
    height: 84px;
    line-height: 30px;
  }

  .nav_right {
    .tell_box {
      height: 84px;
      .tell_top {
        width: 131px;
        color: #bbc4ce;
        text-align: right;
        font-size: 16px;
        img {
          margin-right: 5px;
        }
      }
      .tell_bottom {
        width: 140px;
        font-style: bold;
        color: #bbc4ce;
        text-align: right;
        font-size: 16px;
      }
    }
    .menu_icon {
      width: 34px;
      height: 84px;
      display: none;
    }
  }
  .nav_center {
    width: 100%;
    height: 84px;
    line-height: 84px;
    z-index: 11;
    ul {
      width: 100%;
      transition: all 0.5s;
      padding-left: 0 !important;
      li {
        // width: 16.6%;

        width: 11.5%;
        color: #bbc4ce;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
        // margin: 0 10px;
        font-size: 16px;
      }
      li:hover {
        color: #fff;
      }
      .li_active {
        // background-color: #129afe;
        background-color: #005efe;
        color: #fff;
      }
    }
  }
}


</style>