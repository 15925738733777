
<script setup >
import { ref, defineProps, defineEmits } from "vue";
const emit = defineEmits(["closePop"]);
const isView = ref(false);
const isSuccess = ref(false);
const isClickSubmit = ref(false); //是否点击提交
const errContent = ref("");
const tellRegex = ref(/^[0-9]{11}$/);
const from = ref({
  description: "",
  name: "",
  tell: "",
  company: "",
  code: "",
});

const errTip = ref({
  description: "请输入问题描述",
  name: "请输入姓名",
  tell: "请输入电话",
  company: "请输入公司",
  code: "请输入验证码",
});

const props = defineProps({
  isClosePop: {
    type: Boolean,
    default: true,
  },
});

const handleClose = () => {
  emit("closePop", false);
};

const handleView = () => {
  isSuccess.value = false;
  isView.value = true;
};
const handleBack = () => {
  isView.value = false;
};
const aginMessage = () => {
  isSuccess.value = false;
  isClickSubmit.value = false;
  from.value = {};
};
const submit = () => {
  isClickSubmit.value = true;
  setTimeout(() => {
    errContent.value = "";
  }, 2000);
  for (let key in from.value) {
    if (from.value[key] == "") {
      console.log("key-----", errTip.value[key]);
      errContent.value = errTip.value[key];
      return;
    } else {
      // console.log("提交成功");

      if (from.value.tell) {
        const regex = /^[0-9]{11}$/;
        const isMatch = regex.test(from.value.tell);
        if (isMatch) {
          console.log("该电话号码格式正确");
        } else {
          console.log("该电话号码格式错误");
          errContent.value = "电话号码格式错误";

          return;
        }
      }
      if (from.value.code) {
        if (
          from.value.code.toUpperCase() != "ZEBJ" ||
          from.value.code.toLowerCase() != "zebj"
        ) {
          console.log("验证码错误");
          errContent.value = "验证码错误";
          return;
        } else {
          console.log("验证码成功");
          isSuccess.value = true;
        }
      }
    }
  }
};
</script>
<template>
  <div class="popBox_wrapper">

    <div class="popBox_container" v-show="props.isClosePop">
      <div class="leave_message">
        <div class="top_box flex justify-between align-center">
          <div class="title">给我们留言</div>
          <div
            class="close_box flex justify-center align-center"
            @click="handleClose"
          >
            <div class="close"></div>
          </div>
        </div>
        <div class="content_box">
          <div class="text">
            您好，当前无客服在线，如需帮助，请留言，我们将尽快联系并解决您的问题
          </div>
          <div class="input_wrapper">
            <form action="">
              <div class="description_text">
                <label for="description">问题描述</label>
              </div>
              <div class="description_textarea">
                <textarea
                  id="description"
                  name=""
                  placeholder="请输入留言"
                  v-model="from.description"
                ></textarea>
                <div v-if="isClickSubmit" class="err_tip">
                  {{ from.description ? "" : "请输入问题描述" }}
                </div>
              </div>

              <div class="name_text">
                <label for="name">姓名</label>
              </div>
              <div class="name_textarea">
                <input id="name" v-model="from.name" placeholder="请输入" />
                <div v-if="isClickSubmit" class="err_tip">
                  {{ from.name ? "" : "请输入姓名" }}
                </div>
              </div>
              <div class="tell_text">
                <label for="tell">电话</label>
              </div>
              <div class="tell_textarea">
                <input id="tell" v-model="from.tell" placeholder="请输入" />
                <div v-if="isClickSubmit" class="err_tip">
                  {{
                    from.tell
                      ? tellRegex.test(from.tell)
                        ? ""
                        : "手机号必须是11位数字"
                      : "请输入电话号码"
                  }}
                </div>
              </div>

              <div class="company_text">
                <label for="company">公司</label>
              </div>
              <div class="company_input">
                <input
                  id="company"
                  v-model="from.company"
                  placeholder="请输入"
                />
                <div v-if="isClickSubmit" class="err_tip">
                  {{ from.company ? "" : "请输入公司" }}
                </div>
              </div>

              <div class="code_text">
                <label for="code">验证码</label>
              </div>
              <div class="code_input">
                <div class="code_box flex align-center">
                  <input id="code" v-model="from.code" placeholder="请输入" />
                  <div class="code flex justify-center align-center">ZEBJ</div>
                </div>

                <div v-if="isClickSubmit" class="err_tip">
                  {{ from.code ? "" : "请输入验证码" }}
                </div>
              </div>

              <div class="submit_box flex align-center">
                <div
                  class="submit_btn flex justify-center align-center"
                  @click="submit"
                >
                  提交
                </div>
                <span class="view" @click="handleView">查看消息记录</span>
              </div>
            </form>
          </div>
        </div>

        <div v-if="errContent" class="err_box flex align-center">
          <img src="../assets/images/error.png" alt="" />
          <div class="">{{ errContent }}</div>
        </div>
      </div>

      <div class="message_recording" v-if="isView">
        <div class="top_box flex justify-between align-center">
          <div class="left flex align-center">
            <div class="back" @click="handleBack">
              <img src="../assets/images/back.png" alt="" />
            </div>
            <div class="title">消息记录</div>
          </div>

          <div
            class="close_box flex justify-center align-center"
            @click="handleClose"
          >
            <div class="close"></div>
          </div>
        </div>
        <div class="conotent_box">
          <div class="tip">无更多历史消息</div>
        </div>
      </div>

      <div class="message_success" v-if="isSuccess">
        <div class="top_box flex justify-between align-center">
          <div class="title">给我们留言</div>
          <div
            class="close_box flex justify-center align-center"
            @click="handleClose"
          >
            <div class="close"></div>
          </div>
        </div>
        <div class="content_box">
          <div class="success_icon">
            <img src="../assets/images/success.png" alt="" />
          </div>
          <div class="title">留言提交成功</div>
          <div class="text">我们将尽快与您取得联系并确决您的问题</div>
          <div class="btns" @click="aginMessage">再次留言</div>
          <div class="view" @click="handleView">查看消息记录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.popBox_container {
  position: relative;
  .leave_message {
    position: fixed;
    width: 350px;
    bottom: 2px;
    right: 78px;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 1px #e8e8e8;
    background-color: #fff;
    z-index: 99998;
    transition: all 0.3s;
    animation: show 0.3s;
    .top_box {
      height: 52px;
      width: 100%;
      background-color: #5d7cb6;
      padding: 0 15px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      .title {
        color: #fff;
      }
      .close_box {
        width: 30px;
        height: 30px;
        cursor: pointer;
        .close {
          width: 20px;
          height: 2px;
          background-color: #fff;
          border-radius: 4px;
        }
      }
    }
    .content_box {
      position: relative;
      overflow-y: scroll;
      padding: 0 15px;
      height: 500px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      .err_tip {
        font-size: 14px;
        color: #ff4d4f;
      }

      .text {
        margin-top: 30px;
        font-size: 14px;
      }
      .input_wrapper {
        textarea,
        input {
          border: 1px solid #d4d8da;
          width: 100%;
          border-radius: 2px;
          margin-top: 10px;
          padding-left: 10px;
          font-size: 12px;
        }
        textarea::-webkit-input-placeholder,
        input::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #bfbfbf;
        }

        input {
          height: 30px;
        }
        textarea:hover,
        input:hover {
          border-color: #40a9ff;
          border-right-width: 1px;
        }
        textarea:focus,
        input:focus {
          border-color: #40a9ff;
          box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
          border-right-width: 1px;
          outline: 0;
        }
        label {
          font-weight: bold;
          font-size: 14px;
          color: #000;
          margin-top: 20px;
        }
        label:before {
          display: inline-block;
          margin-right: 4px;
          color: #ff4d4f;
          font-size: 14px;
          font-family: SimSun, sans-serif;
          line-height: 1;
          content: "*";
        }
        .description_text {
        }
        .description_textarea {
          textarea {
            padding-top: 8px;
            height: 90px;
          }
        }

        .code_input {
          margin-bottom: 70px;
          input {
            width: 100px;
            height: 32px;
          }
          .code {
            border: 1px solid #d4d8da;
            width: 120px;
            height: 32px;
            margin-top: 15px;
            margin-left: 10px;
            color: #040387;
            font-style: italic;
            letter-spacing: 11.2px;
          }
        }
        .submit_box {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          position: fixed;
          bottom: 2px;
          padding-top: 20px;
          padding-bottom: 20px;
          height: 60px;
          width: 318px;
          box-sizing: border-box;

          background-color: #fff;

          .submit_btn {
            width: 60px;
            height: 32px;
            cursor: pointer;
            border-radius: 2px;
            background-color: #5d7cb6;

            color: #fff;
            font-size: 14px;
            letter-spacing: 4.8px;
          }
          .view {
            margin-left: 15px;
            font-size: 14px;
            cursor: pointer;
          }
        }
      }
    }
    .err_box {
      position: absolute;
      top: 40px;
      height: 40px;
      // width: 178px;
      background-color: #fff;
      padding: 4px 13px;
      left: 50%;
      transform: translate(-50%, 0);
      box-shadow: 0px 0px 5px 1px #cecccc;
      border-radius: 2px;

      animation: showErr 0.4s;
      transition: all 0.4s;

      img {
        height: 20px;
      }
      div {
        font-size: 14px;
        margin-left: 15px;
      }
    }

    @keyframes showErr {
      from {
        top: 0px;
      }
      to {
        top: 40px;
      }
    }
  }
  @keyframes show {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
  .message_recording {
    position: fixed;
    width: 350px;
    bottom: 0;
    right: 78px;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 1px #e8e8e8;
    background-color: #fff;
    z-index: 99999;
    transition: all 0.2s;
    animation: view 0.2s linear;
    overflow: hidden;
    .top_box {
      height: 53px;
      width: 100%;
      background-color: #5d7cb6;
      padding: 0 15px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      .left {
        .back {
          cursor: pointer;
          img {
            width: 16px;
            vertical-align: middle;
          }
        }
        .title {
          margin-left: 10px;
          padding-top: 2px;
          color: #fff;
        }
      }
      .close_box {
        width: 30px;
        height: 30px;
        cursor: pointer;
        .close {
          width: 20px;
          height: 2px;
          background-color: #fff;
          border-radius: 4px;
        }
      }
    }
    .conotent_box {
      height: 500px;
      background-color: #f5f5f5;
      .tip {
        text-align: center;
        font-size: 12px;
        color: #8c9294;
        padding-top: 20px;
      }
    }
  }
  @keyframes view {
    0% {
      width: 0%;
    }
    100% {
      width: 350px;
    }
  }

  .message_success {
    position: fixed;
    width: 350px;
    bottom: 2px;
    right: 78px;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 1px #e8e8e8;
    background-color: #fff;
    z-index: 99999;
    transition: all 0.5s;
    .top_box {
      height: 52px;
      width: 100%;
      background-color: #5d7cb6;
      padding: 0 15px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      .title {
        color: #fff;
      }
      .close_box {
        width: 30px;
        height: 30px;
        cursor: pointer;
        .close {
          width: 20px;
          height: 2px;
          background-color: #fff;
          border-radius: 4px;
        }
      }
    }
    .content_box {
      height: 500px;
      background-color: #fff;
      text-align: center;
      .success_icon {
        padding-top: 40px;
        img {
          width: 90px;
          object-fit: cover;
        }
      }
      .title {
        font-size: 32px;
        margin-top: 20px;
      }
      .text {
        margin-top: 40px;
        font-size: 14px;
        color: #a7abad;
      }
      .btns {
        width: 100px;
        font-size: 14px;
        margin: 0 auto;
        margin-top: 60px;
        border: 1px solid #d9d9d9;
        padding: 5px 4px;
        cursor: pointer;
      }
      .view {
        margin-top: 20px;
        font-size: 14px;
        color: #595959;
        cursor: pointer;
      }
    }
  }
}
</style>